<template>
  <b-modal v-model="show" title="Compartilhar Dispositivo" class="draggable">
      <b-form @submit.prevent="shareDevice">
        <b-form-group label="Nome do Compartilhamento">
          <b-form-input v-model="shareName"></b-form-input>
        </b-form-group>
        <b-form-group label="Tempo de Validade">
          <b-form-select v-model="shareDuration" :options="durationOptions"></b-form-select>
        </b-form-group>
        <b-button type="submit" variant="primary">Compartilhar</b-button>
      </b-form>
    </b-modal>
  </template>
  
  <script>
  export default {
    props: ['show'],
    data() {
      return {
        shareName: '',
        shareDuration: '30min',
        durationOptions: ['30min', '1h', '1h30m', '2h', '6h', '12h', '24h', 'Custom']
      };
    },
    methods: {
      async shareDevice() {
        try {
          const response = await this.$http.post('/api/share_device', {
            deviceId: this.deviceId,
            shareName: this.shareName,
            shareDuration: this.shareDuration
          });
          alert('Link de compartilhamento: ' + response.data.shareLink);
          this.$emit('close'); // Emitir evento para fechar a modal após compartilhar com sucesso
        } catch (error) {
          console.error('Erro ao compartilhar dispositivo', error);
        }
      }
    }
  };
  </script>
  